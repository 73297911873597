<template>
  <div style="position: relative">
    <loader-spinner no-border-radius-in-overlay centered v-if="isShowOverPageSpinner"></loader-spinner>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>{{ $t('SNMP.SNMPViews.included') }}</th>
          <th>{{ $t('SNMP.SNMPViews.excluded') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in listOfIncludedAndExcludedForShowInTable" :key="key">
          <td>{{ item[0] }}</td>
          <td>{{ item[1] }}</td>
        </tr>
        <tr v-if="!listOfIncludedAndExcludedForShowInTable.length">
          <td colspan="4" class="no-data-row">
            <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import snmpGeneralService from '../../../../services/Administration/SNMP/snmpGeneralService';

export default {
  name: 'SNMPViews',
  data() {
    return {
      generalSNMPSettigns: [],
      included: [],
      excluded: []
    };
  },
  computed: {
    showAllSettingsOnSNMPPartitionPages() {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      return this.$store.state.showAllSettingsOnSNMPPartitionPages;
    },
    listOfIncludedAndExcludedForShowInTable() {
      const result = [];
      if (this.included.length === 0 && this.excluded.length === 0) {
        return result;
      }
      if (this.included.length > this.excluded.length || this.included.length === this.excluded.length) {
        for (const key in this.included) {
          result.push([this.included[key], this.excluded[key] ? this.excluded[key] : '']);
        }
      }
      if (this.included.length < this.excluded.length) {
        for (const key in this.excluded) {
          result.push([this.included[key] ? this.included[key] : '', this.excluded[key]]);
        }
      }
      return result;
    },
    isShowOverPageSpinner() {
      return this.$store.state.loadingSNMPGeneralForViewsRequestExecuting
    }
  },
  methods: {
    getAll() {
      snmpGeneralService.getAllForViews(this);
    }
  },

  created() {
    this.getAll();
  }
};
</script>

<style scoped></style>
