import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

const SNMP_WIRLESS_TRAPS_BLOCKS_NOT_OBJECTS = ['general_controller']; // имена ключей в объекте .snmp_wireless_traps
// которые представляют собой не объекты а просто boolean
// нужно чтобы приводить их к объектам (и обратно) для отображения в карточках на странице SNMP - Wireless traps

export default {

  getAll(ctx) {
    ctx.generalSNMPSettigns = [];
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    ctx.$store.commit('loadingSNMPGeneralBegin');
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/general`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist);
          const listFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist));

          ctx.generalSNMPSettigns = listFromResponse[0];
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingSNMPGeneralEnd');
      },
      (err) => {
        ctx.$store.commit('loadingSNMPGeneralEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },

  getAllForWirelessTraps(ctx) {
    ctx.generalSNMPSettigns = [];
    ctx.wirelessTraps = [];
    ctx.wirelessTrapsForCompare = [];
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    ctx.$store.commit('loadingSNMPGeneralForWirelessTrapsBegin');
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/general`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist);
          const listFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist));

          ctx.generalSNMPSettigns = listFromResponse[0];
          ctx.wirelessTraps = JSON.parse(JSON.stringify(listFromResponse[0].snmp_wireless_traps));


          for (const wirelessTrapBlockName of SNMP_WIRLESS_TRAPS_BLOCKS_NOT_OBJECTS) {
            if (Object.prototype.hasOwnProperty.call(ctx.wirelessTraps, wirelessTrapBlockName) &&
              typeof ctx.wirelessTraps[wirelessTrapBlockName] !== 'object' &&
              typeof ctx.wirelessTraps[wirelessTrapBlockName] === 'boolean') {
              // console.log('here')
              ctx.wirelessTraps[wirelessTrapBlockName] = {[wirelessTrapBlockName]: JSON.parse(JSON.stringify(listFromResponse[0].snmp_wireless_traps))[wirelessTrapBlockName]}
            }
          }
          ctx.wirelessTrapsForCompare = JSON.parse(JSON.stringify(ctx.wirelessTraps))

        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingSNMPGeneralForWirelessTrapsEnd');
      },
      (err) => {
        ctx.$store.commit('loadingSNMPGeneralForWirelessTrapsEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },

  getAllForGeneral(ctx) {
    ctx.generalSNMPSettigns = [];
    ctx.SNMPTrapsAvailable = [];
    ctx.SNMPTrapsEnabled = [];
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    ctx.$store.commit('loadingSNMPGeneralForGeneralBegin');
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/general`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist);
          const listFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist));

          ctx.generalSNMPSettigns = listFromResponse[0];
          ctx.SNMPTrapsAvailable = listFromResponse[0].traps.available;
          ctx.SNMPTrapsEnabled = listFromResponse[0].traps.enabled;
          ctx.systemLocation = listFromResponse[0].system_location;
          ctx.systemContact = listFromResponse[0].system_contacts;
          ctx.SNMPTrapsAvailableForCompare = JSON.parse(JSON.stringify(listFromResponse[0].traps.available));
          ctx.SNMPTrapsEnabledForCompare = JSON.parse(JSON.stringify(listFromResponse[0].traps.enabled));
          ctx.systemLocationForCompare = JSON.parse(JSON.stringify(listFromResponse[0].system_location));
          ctx.systemContactForCompare = JSON.parse(JSON.stringify(listFromResponse[0].system_contacts));


        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingSNMPGeneralForGeneralEnd');
      },
      (err) => {
        ctx.$store.commit('loadingSNMPGeneralForGeneralEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  getAllForViews(ctx) {
    ctx.generalSNMPSettigns = [];
    ctx.included = [];
    ctx.excluded = [];
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    ctx.$store.commit('loadingSNMPGeneralForViewsBegin');
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/general`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist);
          const listFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist));

          ctx.generalSNMPSettigns = listFromResponse[0];
          ctx.included = listFromResponse[0].snmp_view.included;
          ctx.excluded = listFromResponse[0].snmp_view.excluded;

        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingSNMPGeneralForViewsEnd');
      },
      (err) => {
        ctx.$store.commit('loadingSNMPGeneralForViewsEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },


  update(ctx) {
    ctx.$store.commit('updateSNMPGeneralReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.generalSNMPSettigns));

    const updateQuery = {
      action: 'U',
      items: { [updated.id]: updated }
    };

    Vue.axios.post(`${API_URL || ''}/api/snmp_management/general`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPGeneral.notificationUpdatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('updateSNMPGeneralReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('updateSNMPGeneralReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  updateForWirelessTraps(ctx) {
    ctx.$store.commit('updateSNMPGeneralForWirelessTrapsReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.generalSNMPSettigns));
    for (const wirelessTrapBlockName of SNMP_WIRLESS_TRAPS_BLOCKS_NOT_OBJECTS) {
      if (Object.prototype.hasOwnProperty.call(updated.snmp_wireless_traps, wirelessTrapBlockName) && typeof updated.snmp_wireless_traps[wirelessTrapBlockName] === 'object') {
        const wirelessTrapBlockNameValue = updated.snmp_wireless_traps.general_controller[wirelessTrapBlockName];
        delete updated.snmp_wireless_traps[wirelessTrapBlockName];
        updated.snmp_wireless_traps[wirelessTrapBlockName] = wirelessTrapBlockNameValue
      }
    }
    const updateQuery = {
      action: 'U',
      items: { [updated.id]: updated }
    };

    Vue.axios.post(`${API_URL || ''}/api/snmp_management/general`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPGeneral.notificationUpdatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('updateSNMPGeneralForWirelessTrapsReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('updateSNMPGeneralForWirelessTrapsReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  updateForGeneral(ctx) {
    ctx.$store.commit('updateSNMPGeneralForGeneralReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.generalSNMPSettigns));

    const updateQuery = {
      action: 'U',
      items: { [updated.id]: updated }
    };

    Vue.axios.post(`${API_URL || ''}/api/snmp_management/general`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPGeneral.notificationUpdatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('updateSNMPGeneralForGeneralReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('updateSNMPGeneralForGeneralReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },

};
